<template>
  <div
    class="row items-center justify-center with-bgimg"
    style="height: 100vh; background-image: url('../img/bg.jpg');"
  >
    <div>
      <q-card flat bordered style="width: 300px; ">
        <q-card-section class="text-center">
          <img
            :src="`../img/fortebond.png`"
            spinner-color="white"
            style="width:250px"
          />
          <a class="text-h6 ">Sales App</a>
          <br />
          <a class="text-caption text-black">Masukkan username dan password</a>
        </q-card-section>
        <q-card-section class="q-gutter-sm q-mb-sm">
          <q-input
            outlined
            v-model="user.username"
            @keyup.enter="$refs.input_password.focus()"
            ref="input_username"
          >
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            outlined
            v-model="user.password"
            type="password"
            ref="input_password"
            @keyup.enter="login"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
          </q-input>
        </q-card-section>
        <q-btn
          color="warning"
          text-color="black"
          class="full-width no-border-radius"
          @click="login"
          ><span class="text-bold">LOGIN</span></q-btn
        >
      </q-card>
    </div>
  </div>
</template>
<script>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
export default {
  setup() {
    const $http = inject("$http");
    const store = useStore();
    const router = useRouter();
    const $q = useQuasar();

    let user = ref({});

    let login = async () => {
      try {
        var md5 = require("md5");
        let payload = {
          username: user.value.username,
          password: md5(user.value.password),
        };
        let resp = await $http.post(`/login`, payload);
        localStorage.setItem("token", resp.data);
        $q.notify({
          message: "Login Berhasil",
          color: "positive",
        });

        //set vuex
        await store.dispatch("ACC_ROLE", {
          token: localStorage.getItem("token"),
        });

        if (store.state.user.is_manager == "1") {
          router.push("/manager/statistic");
        } else {
          router.push("/visit");
        }
      } catch (err) {
        console.log("proses login gagal");
        console.log(err);
        $q.notify({
          message: "Login Gagal",
          color: "negative",
        });
      }
    };

    return {
      user,
      login,
    };
  },
};
</script>
<style lang="scss">
.with-bgimg {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: gray;
  background-blend-mode: multiply;
}
</style>
